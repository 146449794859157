<template>
  <div class="t-range-slider__wrapper">
    <div class="t-range-slider">
      <input
        ref="inputLeft"
        :max="inputMax"
        :value="internalLeftValue"
        min="0"
        :readonly="readonly"
        step="1"
        type="range"
        @input="readonly ? null : onSetLeftValue()"
      />
      <input
        ref="inputRight"
        :max="inputMax"
        :value="internalRightValue"
        min="0"
        :readonly="readonly"
        step="1"
        type="range"
        @input="readonly ? null : onSetRightValue()"
      />

      <div class="t-range-slider__slider">
        <div class="t-range-slider__track"></div>
        <div ref="range" class="t-range-slider__range"></div>
        <div
          ref="thumbLeft"
          class="t-range-slider__thumb t-range-slider__thumb--left"
        >
          <svg
            class="t-range-slider__thumb-icon"
            fill="none"
            height="32"
            viewBox="0 0 32 32"
            width="32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="16"
              cy="16"
              fill="white"
              r="15.5"
              stroke="currentColor"
            />
            <rect fill="#D2D2D2" height="8" rx="1" width="2" x="13" y="12" />
            <rect fill="#D2D2D2" height="8" rx="1" width="2" x="17" y="12" />
          </svg>
        </div>
        <div
          ref="thumbRight"
          class="t-range-slider__thumb t-range-slider__thumb--right"
        >
          <svg
            class="t-range-slider__thumb-icon"
            fill="none"
            height="32"
            viewBox="0 0 32 32"
            width="32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="16"
              cy="16"
              fill="white"
              r="15.5"
              stroke="currentColor"
            />
            <rect fill="#D2D2D2" height="8" rx="1" width="2" x="13" y="12" />
            <rect fill="#D2D2D2" height="8" rx="1" width="2" x="17" y="12" />
          </svg>
        </div>
      </div>

      <div class="t-range-slider__ruler">
        <div class="t-range-slider__ruler-divisions">
          <div
            v-for="(item, index) in rangeLabels.slice(
              0,
              rangeLabels.length - 1
            )"
            :key="index"
            class="t-range-slider__ruler-division"
          >
            <div class="t-range-slider__ruler-division-line"></div>
            <div class="t-range-slider__ruler-division-text">
              <div>{{ item }}</div>
              <div v-if="index === rangeLabels.length - 2">
                {{ rangeLabels[index + 1] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from "@/core/utils/debounce";

export default {
  props: {
    ranges: {
      type: Array,
      default: () => {
        return [
          {
            value: "<10",
            label: 0
          },
          {
            value: "10",
            label: 10000000
          },
          {
            value: "15",
            label: 15000000
          },
          {
            value: "20",
            label: 20000000
          },
          {
            value: ">20",
            label: null
          }
        ];
      }
    },
    readonly: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: () => [0, 10]
    }
  },

  data() {
    return {
      internalLeftValue: null,
      internalRightValue: null
    };
  },

  computed: {
    inputMax() {
      return this.ranges.length - 1;
    },

    rangeLabels() {
      return this.ranges.map(item => item.value);
    },

    rangeValues() {
      return this.ranges.map(item => item.label);
    }
  },

  watch: {
    internalLeftValue() {
      this.debounceOnChangeValueOfRanges();
    },
    internalRightValue() {
      this.debounceOnChangeValueOfRanges();
    },
    value(val) {
      if (!val || val.length === 0) {
        this.onChangeValueOfRanges();
      }
    }
  },

  created() {
    if (this.value) {
      this.internalLeftValue = this.rangeValues.findIndex(
        item => item === this.value[0]
      );
      this.internalRightValue = this.rangeValues.findIndex(
        item => item === this.value[1]
      );
    }

    this.debounceOnChangeValueOfRanges = debounce(
      this.onChangeValueOfRanges,
      500
    );
  },

  mounted() {
    this.onSetLeftValue();
    this.onSetRightValue();
  },

  methods: {
    onChangeValueOfRanges() {
      const from = this.rangeValues[Number(this.internalLeftValue)];
      const to = this.rangeValues[Number(this.internalRightValue)];

      this.$emit("input", [from, to]);
    },

    onSetLeftValue() {
      const inputLeft = this.$refs.inputLeft;
      const inputRight = this.$refs.inputRight;
      const thumbLeft = this.$refs.thumbLeft;
      const range = this.$refs.range;

      const min = Number(inputLeft.min);
      const max = Number(inputLeft.max);

      inputLeft.value = `${Math.min(
        Number(inputLeft.value),
        Number(inputRight.value) - 1
      )}`;

      const percent =
        ((Number(inputLeft.value) - Number(min)) /
          (Number(max) - Number(min))) *
        100;

      thumbLeft.style.left = `${percent}%`;
      range.style.left = `${percent}%`;

      this.internalLeftValue = Number(inputLeft.value);
    },

    onSetRightValue() {
      const inputLeft = this.$refs.inputLeft;
      const inputRight = this.$refs.inputRight;
      const thumbRight = this.$refs.thumbRight;
      const range = this.$refs.range;

      const min = Number(inputRight.min);
      const max = Number(inputRight.max);

      inputRight.value = String(
        Math.max(parseInt(inputRight.value), parseInt(inputLeft.value) + 1)
      );

      const percent =
        ((Number(inputRight.value) - Number(min)) /
          (Number(max) - Number(min))) *
        100;

      thumbRight.style.right = `${100 - Number(percent)}%`;
      range.style.right = `${100 - Number(percent)}%`;

      this.internalRightValue = Number(inputRight.value);
    }
  }
};
</script>

<style lang="scss">
.t-range-slider {
  padding-top: 14px;

  input[type="range"] {
    -webkit-appearance: none;
    height: 4px;
    opacity: 0.5;
    pointer-events: none;
    position: absolute;
    width: 100%;
    z-index: 2;
  }
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 0 none;
    border-radius: 0;
    height: 52px;
    pointer-events: all;
    width: 52px;
  }
  input[type="range"]::-moz-range-thumb {
    border: 0 none;
    border-radius: 0;
    height: 52px;
    pointer-events: all;
    width: 52px;
  }
}

.t-range-slider__range {
  background-color: #66bb6a;
  border-radius: 10px;
  bottom: 0;
  left: 25%;
  position: absolute;
  right: 25%;
  top: 0;
  transition-duration: 0.2s;
  transition-property: left, right;
  z-index: 2;
}

.t-range-slider__ruler {
  margin-top: 18px;
  padding: 0 10px;
  width: 100%;
}

.t-range-slider__ruler-divisions {
  display: flex;
  margin-left: 16px;
  margin-right: 16px;
}

.t-range-slider__ruler-division {
  flex: 1 1;
}
.t-range-slider__ruler-division-line {
  border-left: 1px solid #dadde0;
  flex: 1 1;
  height: 8px;

  .t-range-slider__ruler-division:last-child & {
    border-right: 1px solid #dadde0;
  }
}

.t-range-slider__ruler-division-text {
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
  color: #57617b;
  font-size: 12px;
  margin-left: -7px;

  .t-range-slider__ruler-division:first-child & {
    margin-left: -11px;
  }

  .t-range-slider__ruler-division:last-child & {
    margin-right: -11px;
  }
}

.t-range-slider__slider {
  height: 4px;
  margin: 0 26px;
  position: relative;
  z-index: 1;
}

.t-range-slider__thumb {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  height: 52px;
  position: absolute;
  transition-duration: 0.2s;
  transition-property: left, right;
  width: 52px;
  z-index: 3;
  &--left {
    transform: translate(-26px, -24px);
  }
  &--right {
    transform: translate(26px, -24px);
  }
}

.t-range-slider__thumb-icon {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 100%;
  height: 32px;
  transition: height 0.3s ease-in-out, width 0.3s ease-in-out;
  width: 32px;
}

.t-range-slider__track {
  background-color: #ecf0f4;
  border-radius: 10px;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.t-range-slider__wrapper {
  position: relative;
  width: 100%;
}
</style>
